.upload-csv {
    margin-top: 10px;
    float: left;
}

.radio-boxes {
    display: flex;
}

.download-template { 
    padding-left: 180px
}

.csv-link {
    display: inline-block; 
}